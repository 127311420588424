const working = process.env.REACT_APP_CLIENT_KEY; // localhost ,zervx-development,starmov
let host;
if (working === "localhost") {
  host = "http://localhost:8080";
}
if (working === "starmov") {
  host = "https://api.appstarmov.com";
}
if (working === "pamworld") {
  host = "https://api.server.pamworld.com";
}
if (working === "jetridenew") {
  host = "https://api2.jetrideapp.com";
}
if (working === "digito") {
  host = "https://api.digito.co.in";
}
if (working === "zayride") {
  host = "https://api.fastrack.site";
}
if (working === "wdy") {
  host = "https://api.wdy.wdy247.co.za";
}
const urls = {
  MODE: working,
  HOST: host,
  REACT_CONFIG: "/api/admin/reactconfig",
  TRACK: "/api/booking/track",
  TRACK_CURRENT_LOCATION: "/api/booking/track/location",
};
module.exports = urls;
